import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [authenticated, setAuthenticated] = useState(false); // State to track authentication
  const [browserInfo, setBrowserInfo] = useState(""); // State to store browser information
  const [uploadTime, setUploadTime] = useState(null); // State to store upload time
  const [uploadSpeed, setUploadSpeed] = useState(null); // State to store upload speed
  const [fileSize, setFileSize] = useState(null); // State to store file size

  useEffect(() => {
    // Fetch configuration file that store what url endpoint that store in /public/config.json
    fetch("/config.json")
      .then((response) => response.json())
      .then((config) => {
        setApiEndpoint(config.REACT_APP_PRE_SIGNED_ENDPOINT);
      })
      .catch((error) => console.error("Error fetching config:", error));

    // Check authentication on component mount
    checkAuthentication();

    // Get browser information (for debugging purposes only)
    const userAgent = navigator.userAgent;
    setBrowserInfo(userAgent);
  }, []);

  // Function to check authentication (for demo purposes only)
  const checkAuthentication = () => {
    const username = prompt("Enter username:");
    const password = prompt("Enter password:");

    // Hardcoded credentials for demo purposes
    const validUsername = "upload";
    const validPassword = "ZzkCB8BvKV8W";

    if (username === validUsername && password === validPassword) {
      setAuthenticated(true);
    } else {
      alert("Invalid credentials. Please try again.");
      checkAuthentication(); // Recursively prompt until valid credentials are entered
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // Calculate and set the file size
    if (file) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2); // Convert bytes to MB
      setFileSize(fileSizeMB);
    }
  };

  // Function to generate the presigned URL
  const getPresignedUrl = async () => {
    // GET request: presigned URL
    const response = await axios({
      method: "GET",
      url: apiEndpoint,
      params: {
        ContentType: selectedFile.type, // Pass the file's content type
      },
      headers: {
        'X-App-Token': process.env.REACT_APP_PRE_SIGNED_TOKEN, // Include the X-App-Token header
      },
    });
    const presignedUrl = response.data.presignedUrl;
    console.log(presignedUrl);
    return {
      presignedUrl: presignedUrl,
      key: response.data.key, // Ensure your API returns the generated key
    };
  };

  // Function to upload the selected file using the generated presigned URL
  const uploadToPresignedUrl = async (presignedUrl) => {
    try {
      // Capture the start time
      const startTime = Date.now();

      // Upload file to presigned URL
      const uploadResponse = await axios.put(presignedUrl, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      });

      // If upload successful, construct the uploaded URL, this will need somehow to store into database
      if (uploadResponse.status === 200) {
        const uploadedKey = presignedUrl.split("?")[0].split("amazonaws.com/")[1];
        const uploadedUrl = `https://content.paiddm.com/${uploadedKey}`;
        setUploadedUrl(uploadedUrl);

        // Capture the end time and calculate the duration,  This will not need for production use for debugging only
        const endTime = Date.now();
        const duration = (endTime - startTime) / 1000; // Duration in seconds
        setUploadTime(duration);

        // Calculate the upload speed
        const fileSizeMB = selectedFile.size / (1024 * 1024); // File size in MB
        const speedMBps = fileSizeMB / duration; // Speed in MB/s
        setUploadSpeed(speedMBps.toFixed(2)); // Round to 2 decimal places
      }

      console.log(uploadResponse);
    } catch (error) {
      // Handle error
      console.error("Error uploading file:", error);
    }
  };

  // Function to orchestrate the upload process
  const handleUpload = async () => {
    try {
      // Ensure a file is selected
      if (!selectedFile) {
        console.error("No file selected.");
        return;
      }

      const { presignedUrl, key } = await getPresignedUrl();
      await uploadToPresignedUrl(presignedUrl);
    } catch (error) {
      // Handle error
      console.error("Error uploading file:", error);
    }
  };

  // This section will not need as it for demo UI only
  // Render authentication prompt until authenticated (for demo purposes)
  if (!authenticated) {
    return null; // Optionally render a loading indicator or nothing until authenticated
  }

  // Render main application once authenticated
  return (
    <div className="App">
      <h1>File Selection Component</h1>
      <p>Browser Information: {browserInfo}</p>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
      <div>Upload Progress: {uploadProgress}%</div>
      {uploadedUrl && (
        <div>
          <strong>Uploaded URL:</strong>{" "}
          <a href={uploadedUrl} target="_blank" rel="noopener noreferrer">
            {uploadedUrl}
          </a>
        </div>
      )}
      {uploadTime !== null && (
        <div>
          <strong>Upload Time:</strong> {uploadTime} seconds
        </div>
      )}
      {uploadSpeed !== null && (
        <div>
          <strong>Average Upload Speed:</strong> {uploadSpeed} MB/s
        </div>
      )}
      {fileSize !== null && (
        <div>
          <strong>File Size:</strong> {fileSize} MB
        </div>
      )}
    </div>
  );
}

export default App;
